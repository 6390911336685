import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import projects from "../../data/projects.json";
import { motion } from "framer-motion";
import "./Projects.css";

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.body.classList.add("modal-open");
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.body.classList.remove("modal-open");
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  const iconSize = windowWidth < 600 ? 'lg' : '2x';

  return (
    <section id="projects">
      <div className="projects-container">
        <h1 className="projects-heading">Projects</h1>
        <div className="loading-card">
          Please give <strong>1 minute</strong> to load the projects as I am using the free tier of Render service.
        </div>
        <div className="projects-grid">
          {projects.map((project, idx) => (
            <motion.div 
              initial={{
                opacity: 0,
                translateY: "20px",
              }}
              whileInView={{
                opacity: 1,
                translateY: 0,
              }}
              viewport={{ once: true }}
              transition={{
                delay: 0.3 + (idx % 2) * 0.2,
                duration: 0.4,
              }}
            className="project-card" key={project.id}>
              <button onClick={() => openModal(project)} className="project-button">
                <img
                  src={project.image}
                  alt={project.title}
                  className="project-image"
                  onMouseOver={(event) => (event.target.src = project.gif)}
                  onMouseOut={(event) => (event.target.src = project.image)}
                />
                <h2 className="project-title">{project.title}</h2>
              </button>
            </motion.div>
          ))}
        </div>
      </div>
      {selectedProject && isModalOpen && (
        <div className="modal-overlay">
          <div className="modal" ref={modalRef}>
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <div className="modal-info">
                <h2>{selectedProject.title}</h2>
                <p>{selectedProject.description}</p>
                <div className="mh-about-tag">
                  <ul className="modal-list">
                    {selectedProject.technologies.map((tech, index) => (
                      <li key={index}>
                        <span>{tech}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <a href={selectedProject.url} className="btn btn-fill" target="_blank" rel="noopener noreferrer">
                  Live Demo
                </a>
                <br />
                <a href={selectedProject.githubUrl} className="link-content" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} size={iconSize} />
                </a>
              </div>
              <div className="modal-image-container">
                <img src={selectedProject.modalImage} alt={selectedProject.title} className="modal-image" />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Projects;